import { Component } from "@angular/core";
import { MessageService } from "../../events/MessageService";
import { navItems } from "../../_nav";

@Component({
  selector: "app-dashboard",
  templateUrl: "./default-layout.component.html",
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;

  constructor(
    private messageService: MessageService
  ) {
    
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  btnProfileClicked() {
    this.messageService.itemBroatcast("PROFILE");
  }

  btnLogoutClicked() {
    this.messageService.itemBroatcast("LOGOUT");
  }
}
