import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { P404Component } from "./views/error/404.component";
import { P500Component } from "./views/error/500.component";
import { PartyDashboardComponent } from "./views/partyDashboard/partyDashboard.component";
import { PartyComponent } from "./views/party/party.component";

export const routes: Routes = [
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: '',
    data: {
      title: "UJAY",
    },
    children: [
      {
        path: '',
        component: PartyComponent,
      },
      {
        path: "partyDashboard",
        component: PartyDashboardComponent
      }
    ]
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
