import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: "LocationModal",
    templateUrl: "./locationModal.component.html",
})
export class LocationModalComponent implements OnInit {
    @ViewChild("locationModal") public thisModal: ModalDirective;
    @Output() btnClicked = new EventEmitter<string>();
    @Output() itemLocationClicked = new EventEmitter<any>();

    addressName: string;
    address: string;
    locationIcon = "./assets/img/ic_location_check.png";
    isActive = false;

    nearByLocations = [];

    constructor() { }

    ngOnInit(): void {

    }

    updateInfo(addressName: string, address: string) {
        this.addressName = addressName;
        this.address = address;
    }

    onLocationSelect(item: any): void {
        this.itemLocationClicked.emit(item);
    }

    btnLocationContinueClicked() {
        this.btnClicked.emit("");
    }
}
