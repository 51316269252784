import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { SongModel } from "../../../models/SongModel";

@Component({
    selector: "UpvoteModal",
    templateUrl: "./upvoteModal.component.html",
})
export class UpvoteModalComponent {
    @ViewChild("upvoteModal") public thisModal: ModalDirective;
    @Output() btnSpotifyClickedEvent = new EventEmitter<void>();
    @Output() btnAppleMusicClickedEvent = new EventEmitter<void>();
    @Output() btnUpvoteClickedEvent = new EventEmitter<void>();
    @Output() btnDownvoteClickedEvent = new EventEmitter<void>();

    @Input() currentSongThumb: string;
    @Input() currentSongTitle: string;
    @Input() currentSongArtist: string;
    @Input() currentRequestedBy: string;
    @Input() currentAppleMusic: string;

    constructor() {

    }

    btnUpvoteClicked() {
        this.btnUpvoteClickedEvent.emit();
    }

    btnDownvoteClicked() {
        this.btnDownvoteClickedEvent.emit();
    }

    btnSpotifyClicked() {
        this.btnSpotifyClickedEvent.emit();
    }

    btnAppleMusicClicked() {
        this.btnAppleMusicClickedEvent.emit();
    }

    btnCloseUpvoteClicked() {
        this.thisModal.hide();
    }
}
