import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'TipDJModal',
  templateUrl: './TipDJModal.component.html'
})
export class TipDJModalComponent implements OnInit, AfterViewInit {
  @ViewChild("tipDJModal") public tipDJModal: ModalDirective;
  customTip: number;
  @Output() btnClicked = new EventEmitter<string>();

  djEmail: string;
  item1: string;
  item2: string;
  item3: string;

  constructor() { }

  ngOnInit(): void {
    this.item1 = "/assets/img/ic_beer_1.png";
    this.item2 = "/assets/img/ic_beer_2.png";
    this.item3 = "/assets/img/ic_beer_3.png";
  }

  ngAfterViewInit(): void {

  }

  updateItemIcon() {
    this.item1 = "/assets/img/ic_beer_1.png";
    this.item2 = "/assets/img/ic_beer_2.png";
    this.item3 = "/assets/img/ic_beer_3.png";
  }

  btnTipDJ1Clicked() {
    this.btnClicked.emit("btnTipDJ1Clicked")
  }

  btnTipDJ2Clicked() {
    this.btnClicked.emit("btnTipDJ2Clicked")
  }

  btnTipDJ3Clicked() {
    this.btnClicked.emit("btnTipDJ3Clicked")
  }

  btnTipDJCustomTipClicked() {
    this.btnClicked.emit("btnTipDJCustomTipClicked")
  }

  btnCancelTipDJModalClicked() {
    this.tipDJModal.hide();
  }
}
