import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/AuthService";
import { AppService } from "../../services/app.service";
import { Location } from "@angular/common";
import { BaseComponent } from "./base.component";
import { Constants } from "../../helpers/Constants";
import { WebService } from "../../webServices/WebService";

declare var $: any;
declare var window: any;
declare var google: any;

export abstract class GoogleComponent extends BaseComponent {

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public http: HttpClient,
    public webService: WebService,
    public toastr: ToastrService,
    public spinner: NgxSpinnerService,
    public authAppService: AuthService,
    public appService: AppService,
    public location: Location
  ) {
    super(
      router,
      route,
      authAppService,
      appService,
      http,
      webService,
      toastr,
      spinner,
      location
    );
  }

  loadGoogleScript() {
    this.loadExternalScript(
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDgJmdJ8zIRjCJL8eWGsy0FIItTgulrLK0&libraries=places&signed_in=true"
    ).then(() => {
      this.initGoogleSearchBox();
    });
  }

  initGoogleSearchBox() {
    var centerSfo = new google.maps.LatLng(this.lat, this.lng);
    var circle = new google.maps.Circle({ radius: 1000, center: centerSfo });
    var options = {};
    if (this.lat) {
      options = {
        bounds: circle.getBounds(),
        strictBounds: true
      }
    } else {
      options = {
        strictBounds: false
      }
    }
    const input = document.getElementById("searchLocationInput");
    const searchBox = new google.maps.places.Autocomplete(input, options);
    searchBox.addListener("place_changed", () => {
      this.handleGoogleSearchResult(searchBox);
    });

    const inputGoogle = document.getElementById("searchLocationGoogleInput");
    const searchBoxGoogle = new google.maps.places.Autocomplete(inputGoogle, options);
    searchBoxGoogle.addListener("place_changed", () => {
      this.handleGoogleSearchResult(searchBoxGoogle);
    });
  }

  locationGoogleTextChanged(q: string) {
    if (q.toLocaleLowerCase() == "turbo's playhouse"
      || q.toLocaleLowerCase() == "turbos playhouse"
      || q.toLocaleLowerCase() == "turbo"
      || q.toLocaleLowerCase() == "turbos"
      || q.toLocaleLowerCase() == "playhouse") {
      this.addressName = "Turbo’s Playhouse (Living Room)";
      this.address = "14 Westminster Ave C, Venice, CA 90291, USA";
      this.currentPlaceId = "ChIJkff1BgC7woARobG--Fs6SwY";
      this.lat = 33.9886307;
      this.lng = 118.4744997;
      localStorage.setItem("lat", this.lat);
      localStorage.setItem("lng", this.lng);
      localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
      localStorage.setItem(Constants.SETTINGS_PLACEID, this.currentPlaceId);
      this.noLocation = false;

      this.appService.Venue = this.addressName;

      this.askLocationModal.hide();
      this.locationModalComponent.updateInfo(this.addressName, this.address);
      this.locationModalComponent.thisModal.hide();

      this.locationGoogleModal.hide();
      this.GetSongRequestByStandard(false);
      this.GetSpotifyPlaylist();
      this.googlePlaceSelected();
    }
  }

  handleGoogleSearchResult(searchBox) {

    this.locationGoogleModal.hide();
    this.askLocationModal.hide();
    this.locationModalComponent.thisModal.hide();

    const firstPlace = searchBox.getPlace();
    const position = firstPlace.geometry.location;
    var vicinity = firstPlace.vicinity
    if (vicinity) {
      this.address = firstPlace.formatted_address;
    } else {
      this.address = firstPlace.formatted_address;
    }
    this.currentPlaceId = firstPlace.place_id;
    this.lat = position.lat();
    this.lng = position.lng();

    var placeType = "";
    if(firstPlace.types != null && firstPlace.types.length > 0) {
      placeType = firstPlace.types[0];
    }
    if(placeType != "street_address" && placeType != "premise") {
      this.addressName = firstPlace.name;
      this.appService.Venue = this.addressName;

      this.locationModalComponent.updateInfo(this.addressName, this.address);

      localStorage.setItem("lat", this.lat);
      localStorage.setItem("lng", this.lng);
      localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
      localStorage.setItem(Constants.SETTINGS_PLACEID, this.currentPlaceId);
      this.noLocation = false;
  
      if (this.sessionType == 3) {
        this.GetSongRequestByStandard(false);
        this.GetSpotifyPlaylist();
        this.googlePlaceSelected();
      } else if (this.sessionType == 4) {
        this.GetSongRequest();
      } else {
        this.getSession(false, false);
      }
    } else {
      this.locationModalComponent.thisModal.hide();
      this.createPartyModal.show();
    }
  }

  abstract googlePlaceSelected(): void;
}