import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
  } from "@angular/core";
  import { ModalDirective } from "ngx-bootstrap/modal";
  
  @Component({
    selector: "PartyPremiumRequestModal",
    templateUrl: "./partyPremiumRequestModal.component.html",
  })
  export class PartyPremiumRequestModalComponent implements OnInit {
    @ViewChild("partyPremiumRequestModal") public thisModal: ModalDirective;
    @Output() btnClicked = new EventEmitter<string>();

    @Input() currentSongThumb: string;
    @Input() currentSongTitle: string;
    @Input() currentSongArtist: string;

    constructor() { }
  
    ngOnInit(): void {
      
    }

    btnConfirmClicked() {
      this.btnClicked.emit("");
    }
  }
  