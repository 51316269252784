import {HttpClient} from '@angular/common/http';
import {Component, ElementRef, ViewChild,} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalDirective} from 'ngx-bootstrap/modal';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastrService} from 'ngx-toastr';
import {AuthService} from '../../auth/AuthService';
import {Constants} from '../../helpers/Constants';
import {AppService} from '../../services/app.service';
import {Location} from '@angular/common';
import {EventModel} from '../../models/EventModel';
import {SongModel} from '../../models/SongModel';
import {Helpers} from '../../helpers/Helpers';
import {StandardSessionComponent} from '../modal/standard/standard.component';
import {v4 as uuidv4} from 'uuid';
import {LocationModalComponent} from '../modal/locationModal/locationModal.component';
import {SearchSongModalComponent} from '../modal/searchSongModal/searchSongModal.component';
import {WebService} from '../../webServices/WebService';
import {SongRequestModel} from '../../models/SongRequestModel';

declare var $: any;
declare var window: any;
declare var googletag: any;

@Component({
    template: ''
})
export abstract class BaseComponent {
    @ViewChild('askLocationModal') public askLocationModal: ModalDirective;
    @ViewChild('locationGoogleModal') public locationGoogleModal: ModalDirective;
    @ViewChild('marquee') public marquee: ElementRef;
    @ViewChild('createPartyModal') public createPartyModal: ModalDirective;

    @ViewChild(StandardSessionComponent) standardSessionComponent: StandardSessionComponent;
    @ViewChild(LocationModalComponent) locationModalComponent: LocationModalComponent;
    @ViewChild(SearchSongModalComponent) searchSongModalComponent: SearchSongModalComponent;

    sessionType = 1;

    isPremium = false;
    isCollectPhoneNumbers_party = '2';
    isOnlyPickSongPlaylists_party = '2';
    isFullyControl_party = '2';

    events = [];
    songs = [];
    playlists = [];
    breakSongs = [];
    currentPlaylistSongs = [];
    locations = [];
    currentRequestedKey = '';
    key: string;
    event: EventModel;
    requestType = '1';
    addressName = '';
    address: any;
    shortlyAddress = '';
    currentPlaceId = '';
    lat: any;
    lng: any;
    noLocation = false;
    requesterName = '';

    searchKeyword: string;
    createPartyKeyword: string;
    isSearchSong = true;
    isOnBoard = false;
    signature: string;

    findLocationTitle = 'Find Nearby DJ Session';
    findLocationDes =
        'Tell us your current location or a location near the session you’re looking for.';

    btnWrongVenueClickedEvent: any;

    spotifyPlaylistId = '';
    spotifyPlaylistKey = '';
    isEmptyPlaylist = true;
    startPlaylistMinutes = 0;
    isWhitelisted = false;
    hasSYBPlaylist = false;
    lastUpdated = 0;

    tipLink = '';
    socialLink = '';

    protected constructor(
        public router: Router,
        public route: ActivatedRoute,
        public authAppService: AuthService,
        public appService: AppService,
        public http: HttpClient,
        public webService: WebService,
        public toastr: ToastrService,
        public spinner: NgxSpinnerService,
        public location: Location
    ) {

    }

    AfterRequestModalBtnClicked(event: string) {
        switch (event) {
            case 'btnYesClicked':
                break;
            case 'btnCancelClicked':
                this.location.back();
                break;
        }
    }

    loadEvent() {
        const key = localStorage.getItem(Constants.EVENT_KEY);
        const eventName = localStorage.getItem(Constants.EVENT_NAME);
        const uid = localStorage.getItem(Constants.EVENT_UID);
        const djEmail = localStorage.getItem(Constants.EVENT_DJEMAIL);
        const djName = localStorage.getItem(Constants.EVENT_DJNAME);
        const profileUrl = localStorage.getItem(Constants.EVENT_PROFILE_URL);
        const venue = localStorage.getItem(Constants.EVENT_VENUE);
        const isKaraoke = localStorage.getItem(Constants.EVENT_IS_KARAOKE);
        const isTipping = localStorage.getItem(Constants.EVENT_IS_TIPPING);
        const isCollectEmails = localStorage.getItem(Constants.EVENT_IS_COLLECTS_EMAILS);
        const isCollectPhoneNumbers = localStorage.getItem(Constants.EVENT_IS_COLLECTS_PHONE_NUMBERS);

        this.event = {
            key: key,
            cover: '',
            eventName: eventName,
            genres: '',
            address: '',
            venue: venue,
            uid: uid,
            djEmail: djEmail,
            djName: djName,
            profileUrl: profileUrl,
            isKaraoke: isKaraoke,
            isTipping: isTipping,
            enable: '',
            isCollectEmails: isCollectEmails,
            isCollectPhoneNumbers: isCollectPhoneNumbers
        };

        if (this.event.venue === '' || typeof this.event.venue === 'undefined') {
            this.appService.Venue = ' UNKNOWN';
        } else {
            this.appService.Venue = ' ' + this.event.venue;
        }
    }

    calculateDistanceForGoogleGeo(lat1: any, lng1: any, lat2: any, lng2: any): number {
        function deg2rad(deg) {
            return deg * (Math.PI / 180);
        }

        function square(x) {
            return Math.pow(x, 2);
        }

        const r = 6371;
        lat1 = deg2rad(lat1);
        lat2 = deg2rad(lat2);
        const lat_dif = lat2 - lat1;
        const lng_dif = deg2rad(lng2 - lng1);
        const a = square(Math.sin(lat_dif / 2)) + Math.cos(lat1) * Math.cos(lat2) * square(Math.sin(lng_dif / 2));
        return 2 * r * Math.asin(Math.sqrt(a));
    }

    async rearrange() {
        const temps = this.locations;
        this.locations = [];
        temps.sort((a, b) => b.distance - a.distance);
        temps.forEach(s => {
            this.locations.push(s);
        });
        this.currentPlaceId = this.locations[0].place_id;
        this.appService.Venue = this.locations[0].formatted_address;
        this.address = this.locations[0].formatted_address;
        const addresses = this.locations[0].formatted_address.split(',');
        if (addresses && addresses.length > 0) {
            this.shortlyAddress = addresses[0];
        } else {
            this.shortlyAddress = this.address;
        }
        localStorage.setItem(Constants.SETTINGS_ADDRESS, this.address);
        this.locationModalComponent.updateInfo(this.addressName, this.address);
        this.locationModalComponent.thisModal.show();
        await this.nearbyParty();
        await this.nearbySearch();
    }

    async nearbySearch() {
        let root = await this.webService.nearbySearch(this.lat, this.lng);
        if (root && root.data && root.data.length > 0) {
            let placeIds = '';
            root.data.forEach(place => {
                const distance = this.calculateDistanceForGoogleGeo(this.lat, this.lng,
                    place.location.latitude, place.location.longitude);
                if (this.locations.length > 0) {
                    if (distance <= 0.01) {
                        this.appService.Venue = place.displayName.text;
                        this.addressName = place.displayName.text;
                        this.address = place.formattedAddress;
                        this.currentPlaceId = place.id;

                        this.locationModalComponent.updateInfo(this.addressName, this.address);
                    }
                }
                place.distance = (distance * 0.621371192).toFixed(2) + ' miles';
                place.realDistance = distance;
                place.isActive = false;
                place.isParty = false;
                placeIds += place.id + ',';

                this.locationModalComponent.nearByLocations.push(place);
            });
            this.reorderLocation();
            if (placeIds !== '') {
                placeIds = placeIds.substring(0, placeIds.length - 1);
            }
            await this.getPlaylistByPlaceIds(placeIds);
        }
    }

    async nearbyParty() {
        const root = await this.webService.NearbyParty(this.lat, this.lng);
        if (root && root.data && root.data.length > 0) {
            let placeIds = '';
            root.data.forEach(place => {
                place.id = place.placeId;
                place.displayName = {};
                place.displayName.text = place.eventName;
                place.formattedAddress = place.address;

                place.location = {};
                place.location.latitude = place.latitude;
                place.location.longitude = place.longitude;

                const distance = this.calculateDistanceForGoogleGeo(this.lat, this.lng,
                    place.latitude, place.longitude);
                if (distance <= 0.02) {
                    this.appService.Venue = place.displayName.text;
                    this.addressName = place.displayName.text;
                    this.address = place.formattedAddress;
                    this.currentPlaceId = place.id;

                    this.createPartyModal.hide();

                    this.locationModalComponent.updateInfo(this.addressName, this.address);
                }
                place.distance = (distance * 0.621371192).toFixed(2) + ' miles';
                place.realDistance = distance;
                place.isParty = true;
                placeIds += place.id + ',';
                this.locationModalComponent.nearByLocations.push(place);
            });
            this.reorderLocation();
            if (placeIds !== '') {
                placeIds = placeIds.substring(0, placeIds.length - 1);
            }
            await this.getPlaylistByPlaceIds(placeIds);
        }
    }

    reorderLocation() {
        this.locationModalComponent.nearByLocations.sort((a, b) => a.realDistance - b.realDistance);
    }

    async convertLatLngToAddress() {
        let root = await this.webService.convertLatLngToAddress(this.lat, this.lng);
        if (root && root.results && root.results.length > 0) {
            const found = this.locations.find((obj) => {
                return obj.place_id === root.results[0].place_id;
            });
            let distance = this.calculateDistanceForGoogleGeo(
                this.lat, this.lng,
                root.results[0].geometry.location.lat,
                root.results[0].geometry.location.lng);
            if (!found) {
                root.results[0].distance = distance;
                this.locations.push(root.results[0]);
                await this.rearrange();
                await this.getPlaylistByPlaceId(this.locations[0].place_id);
            } else {
                await this.getPlaylistByPlaceId(found.place_id);
            }
        } else {
            this.appService.Venue = 'UNKNOWN';
        }
    }

    onVenueSelect(item: EventModel) {
        this.key = item.key;
        this.locationModalComponent.thisModal.hide();
        this.router.navigate([''], {queryParams: {key: item.key}});
    }

    generateUUID(): string {
        let savedUUID = localStorage.getItem(
            Constants.SETTINGS_UUID
        );
        if (savedUUID === undefined || savedUUID === null) {
            let uuid = uuidv4();
            localStorage.setItem(Constants.SETTINGS_UUID, uuid);
            savedUUID = uuid;
        }

        return savedUUID;
    }

    songRequested(data: [SongRequestModel]) {
        let result = Helpers.groupBy(data, (x) => x.songKey);
        let temp = [];
        this.songs = [];
        for (let key in result) {
            const songFirst = result[key][0];
            let noRequested = songFirst.noRequested;
            noRequested += result[key].length - 1;
            if (isNaN(noRequested)) {
                noRequested = 0;
            }
            songFirst.noRequested = noRequested;
            temp.push(songFirst);
        }
        const tempSongs = [];
        const uuid = this.generateUUID();
        temp.forEach((element) => {
            let upvoted = false;
            let downvoted = false;
            if (element.uuids.includes(uuid)) {
                upvoted = true;
            }
            if (element.downVoteUuids.includes(uuid)) {
                downvoted = true;
            }
            const isExplicit = element.explicit != '0';

            const s = {
                key: element.key, Uri: element.songKey,
                TrackId: element.songKey, Artist: element.songArtist,
                Thumb: element.songThumb, Title: element.songName,
                Enable: true, NoRequested: element.noRequested,
                Requester: element.firstName + ' ' + element.lastName,
                AppleMusic: element.link_apple_music,
                UUID: element.uuid, Upvoted: upvoted, Downvoted: downvoted,
                Time: element.time,
                ItemType: 1,
                Duration: Number(element.duration_ms),
                Explicit: isExplicit,
                RequesterText: 'Requested by ' + element.Requester
            } as SongModel;
            tempSongs.push(s);
        });
        tempSongs.sort((a, b) => a.Time - b.Time);
        tempSongs.sort((a, b) => b.NoRequested - a.NoRequested);

        let countDuration = 0;
        let hasSeparator = false;
        tempSongs.forEach(s => {
            countDuration += s.Duration;
            if (!hasSeparator && countDuration > 1500000) {
                const separator = {
                    Duration: 0,
                    ItemType: 2
                } as SongModel;
                this.songs.push(separator);
                hasSeparator = true;
            }
            this.songs.push(s);
        });
        this.standardSessionComponent.updateSongs(this.songs);
    }

    async GetSongRequestByStandard(isSongRequest: Boolean) {
        this.showLoading();
        const root = await this.webService.GetSongRequestByStandard(this.currentPlaceId, this.addressName);
        if (root.code === 0) {
            if (root.data.length == 0) {
                this.searchSongModalComponent.updateStaticModal(true);
                this.searchSongModalComponent.thisModal.show();
            } else {
                this.searchSongModalComponent.thisModal.hide();
                if (isSongRequest
                    && root.data.length == Constants.NUMBER_OF_SONG_REQUEST
                    && this.startPlaylistMinutes == 0
                    && this.isFullyControl_party == '2') {
                    await this.StartSpotifyPlaylist();
                }
            }
            this.hideLoading();
            this.songRequested(root.data);
        } else {
            this.toastr.error('Error', root.msg);
        }
    }

    async StartSpotifyPlaylist() {
        const t = Date.parse(Date());
        const minutes = Math.floor((t / 1000 / 60) % 60);
        const key = localStorage.getItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_KEY);

        this.startPlaylistMinutes = minutes;
        localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_MIN, minutes.toString());

        if (key) {
            await this.webService.StartPlaylist(key, this.spotifyPlaylistId, this.currentPlaceId, minutes.toString());
            await this.GetPlaylistSuggestion();
            await this.GetSongRequestByStandard(false);
        }
    }

    async getPlaylistByPlaceId(placeId: string) {
        let root = await this.webService.GetPlaylistByPlaceId(placeId);
        if (root.code == 0 && root.data) {
            let lastUpdated = root.data.lastUpdated;
            this.lastUpdated = lastUpdated;
            if (lastUpdated) {
                const now = Date.now();
                this.locationModalComponent.isActive = now - lastUpdated < 3600000;
            }
        }
    }

    async getPlaylistByPlaceIds(placeIds: string) {
        let root = await this.webService.getPlaylistByPlaceIds(placeIds);
        if (root.code === 0) {
            const now = Date.now();
            root.data.forEach(playlist => {
                const lastUpdated = playlist.lastUpdated;
                if (lastUpdated) {
                    if (now - lastUpdated < 3600000) {
                        const found = this.locationModalComponent.nearByLocations.find((obj) => {
                            return obj.id === playlist.placeId;
                        });
                        if (found) {
                            found.isActive = true;
                        }
                    }
                }
            });
        }
    }

    async GetSpotifyPlaylist() {
        if (this.currentPlaceId == undefined
            || this.currentPlaceId == '') {
            return;
        }
        const root = await this.webService.GetSpotifyPlaylist(this.currentPlaceId, this.addressName);
        if (root.code == 0 && root.data && Object.keys(root.data).length != 0) {
            this.spotifyPlaylistId = root.data.id;
            this.spotifyPlaylistKey = root.data.key;
            let isNew = root.data.isNew;
            this.isEmptyPlaylist = !!isNew;
            let min = root.data.min;
            if (min != undefined && min != 0) {
                this.isWhitelisted = false;
                this.standardSessionComponent.isStartedPlaylist = true;
                this.startPlaylistMinutes = Math.round(Number(min));
                this.standardSessionComponent.removeItemType3FromSongs();
            } else {
                this.isWhitelisted = true;
                this.standardSessionComponent.setIsStartedPlaylist();
                this.startPlaylistMinutes = 0;
            }

            this.isOnBoard = !!root.data.autoUpdate;

            if (root.data.playlistSYBId != '') {
                this.hasSYBPlaylist = true;
            }
            localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID, this.spotifyPlaylistId);
            localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_KEY, this.spotifyPlaylistKey);
            localStorage.setItem(Constants.SETTINGS_SYB_PLAYLISTID, root.data.playlistSYBId);
            localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_MIN, this.startPlaylistMinutes.toString());
            this.refreshPage();
            await this.GetSongRequestTicker();
            await this.GetSpotifyPlaylistSuggest(this.spotifyPlaylistKey);
        } else {
            this.spotifyPlaylistId = '';
            this.spotifyPlaylistKey = '';
            localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID, this.spotifyPlaylistId);
            localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_KEY, this.spotifyPlaylistKey);
            await this.GetPlaylistSuggestion();
        }
    }

    async GetSpotifyPlaylistSuggest(playlistId: string) {
        if(this.playlists.length > 0) {
            return;
        }
        let root = await this.webService.getSpotifyPlaylistSuggest(playlistId);
        if (root.code == 0) {
            this.breakSongs = [];
            root.data.forEach((track) => {
                let artistName = '';
                let imageUrl = '';
                if (track.album.images != null && track.album.images.length > 0) {
                    imageUrl = track.album.images[0].url;
                }
                track.artists.forEach((artist) => {
                    artistName += artist.name + ', ';
                });

                artistName = artistName.trim().slice(0, -1);
                track.imageUrl = imageUrl;
                track.artistName = artistName;
                this.breakSongs.push(track);
            });
            if (this.breakSongs.length == 0) {
                await this.GetPlaylistSuggestion();
            } else {
                this.isSearchSong = false;
            }
        }
    }

    async GetPlaylistSuggestion() {
        if (this.playlists.length > 0) {
            return;
        }
        const root = await this.webService.GetPlaylistSuggestion();
        if (root != null && root.code == 0) {
            root.data.forEach((track) => {
                let artistName = '';
                let imageUrl = '';
                if (track.album.images != null && track.album.images.length > 0) {
                    imageUrl = track.album.images[0].url;
                }
                track.artists.forEach((artist) => {
                    artistName += artist.name + ', ';
                });

                artistName = artistName.trim().slice(0, -1);
                track.imageUrl = imageUrl;
                track.artistName = artistName;
                this.breakSongs.push(track);
            });
            if (this.breakSongs.length != 0) {
                this.isSearchSong = false;
                this.isPremium = false;
            }
        }
    }

    async GetSongRequestTicker() {
        let root = await this.webService.getSongRequestByStandardForTicker(this.currentPlaceId, this.addressName);
        this.hideLoading();
        if (root.code == 0) {
            const data = [];
            this.currentPlaylistSongs = root.data;
            let s: SongRequestModel;
            if (this.isPremium) {
                if (this.tipLink != undefined && this.tipLink != '') {
                    s = {requestType: '4', requestValue: `Tip the DJ`, songThumb: 'assets/img/ic_tip_dj.png'} as SongRequestModel;
                    data.push(s);
                }

                if (this.socialLink != undefined && this.socialLink != '') {
                    s = {
                        requestType: '5',
                        requestValue: `Follow the DJ`,
                        songThumb: 'assets/img/ic_follow_dj.png'
                    } as SongRequestModel;
                    data.push(s);
                }
            }

            if (root.data.length > 0) {
                s = {requestType: '3', requestValue: 'CURRENT PLAYLIST '} as SongRequestModel;
            } else {
                if (this.standardSessionComponent.isStartedPlaylist) {
                    if (this.addressName == '') {
                        s = {
                            requestType: '3',
                            requestValue: `When the countdown hits zero, "${this.shortlyAddress}" playlist will update with the top song requests`
                        } as SongRequestModel;
                    } else {
                        s = {
                            requestType: '3',
                            requestValue: `When the countdown hits zero, "${this.addressName}" playlist will update with the top song requests`
                        } as SongRequestModel;
                    }
                } else {
                    if (this.isFullyControl_party == '2') {
                        if (this.addressName == '') {
                            s = {
                                requestType: '3',
                                requestValue: `${this.shortlyAddress} playlist will start after ${Constants.NUMBER_OF_SONG_REQUEST} song requests`
                            } as SongRequestModel;
                        } else {
                            s = {
                                requestType: '3',
                                requestValue: `${this.addressName} playlist will start after ${Constants.NUMBER_OF_SONG_REQUEST} song requests`
                            } as SongRequestModel;
                        }
                    } else {
                        s = null;
                    }
                }
            }
            if (s != null) {
                data.push(s);
            }
            root.data.forEach(element => {
                data.push(element);
            });
            this.songRequestedTicker(data);
        }
    }

    songRequestedTicker(data) {
        if(!this.marquee)
            return;
        if (this.standardSessionComponent.isStartedPlaylist) {
            $('#tickerRefreshPlaylist').css({
                'height': '50px',
                'display': 'block',
                'visibility': 'visible'
            });
            $('#marquee').css(
                'height', '50px'
            );
            $('#footer').css(
                'height', '100px'
            );
        } else {
            $('#tickerRefreshPlaylist').css({
                'height': '0px',
                'display': 'none',
                'visibility': 'hidden !important',
            });
            $('#footer').css(
                'height', '50px'
            );
        }

        const speed = (data.length + 1) * 4;
        $('#marqueeP').css(
            'animation',
            `sidescroll ${speed}s linear infinite`
        );

        this.marquee.nativeElement.innerHTML = '';

        data.forEach((element) => {
            if (
                element.requestType == '1' ||
                element.requestType == '2' ||
                element.requestType == '3' ||
                element.requestType == '4' ||
                element.requestType == '5'
            ) {
                let name: string;
                if (
                    element.firstName === 'Anonymous' ||
                    element.firstName === ''
                ) {
                    name = 'Someone';
                } else {
                    name = element.firstName;
                }

                if (element.requestType === '1' || element.requestType === '2'
                    || element.requestType === '4' || element.requestType === '5') {
                    const img = element.songThumb;
                    const htmlImg =
                        '<img alt="" class="songThumbMarquee center-crop" src="' +
                        img +
                        '"' +
                        '</img>';
                    this.marquee.nativeElement.insertAdjacentHTML(
                        'beforeend',
                        htmlImg
                    );
                }

                if (element.requestType === '1' || element.requestType === '2') {
                    const html3 =
                        '<a class="requestBoldSpan" (click)="btnPlaylistClicked();">' + element.songName + '</a>';
                    this.marquee.nativeElement.insertAdjacentHTML(
                        'beforeend',
                        html3
                    );

                    if (name !== 'Someone') {
                        const html2 = '<a class="requestedSpan">requested by</a>';
                        this.marquee.nativeElement.insertAdjacentHTML(
                            'beforeend',
                            html2
                        );

                        const html1 = '<a class="requestBoldSpan">' + name + '</a>';
                        this.marquee.nativeElement.insertAdjacentHTML(
                            'beforeend',
                            html1
                        );
                    } else {
                        const html2 = '<a class="requestedSpan">-</a>';
                        this.marquee.nativeElement.insertAdjacentHTML(
                            'beforeend',
                            html2
                        );

                        const html1 =
                            '<a class="requestBoldSpan">' + element.songArtist + '</a>';
                        this.marquee.nativeElement.insertAdjacentHTML(
                            'beforeend',
                            html1
                        );
                    }
                } else if (element.requestType === '3') {
                    const html3 =
                        '<a class="requestBoldSpan">' +
                        element.requestValue +
                        '</a>';
                    this.marquee.nativeElement.insertAdjacentHTML(
                        'beforeend',
                        html3
                    );
                } else if (element.requestType === '4') {
                    const html3 =
                        '<a href=' + this.tipLink + ' target="_blank" class="requestBoldSpan">' +
                        element.requestValue +
                        '</a>';
                    this.marquee.nativeElement.insertAdjacentHTML(
                        'beforeend',
                        html3
                    );
                } else if (element.requestType === '5') {
                    const html3 =
                        '<a  href=' + this.socialLink + ' target="_blank"  class="requestBoldSpan">' +
                        element.requestValue +
                        '</a>';
                    this.marquee.nativeElement.insertAdjacentHTML(
                        'beforeend',
                        html3
                    );
                }
                this.marquee.nativeElement.insertAdjacentHTML(
                    'beforeend',
                    '<a class="bulletSpan">&#8226;</a>'
                );
            }
        });
    }

    async GetSpotifyPlaylistAndGoTo() {
        this.showLoading();
        let root = await this.webService.getSpotifyPlaylist(this.address);
        this.hideLoading();
        if (root.code === 0) {
            this.spotifyPlaylistId = root.data.id;
            localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLISTID, root.data.id);
            localStorage.setItem(Constants.SETTINGS_SPOTIFY_PLAYLIST_KEY, root.data.key);
            localStorage.setItem(Constants.SETTINGS_SYB_PLAYLISTID, root.data.playlistSYBId);
            window.open('https://open.spotify.com/playlist/' + this.spotifyPlaylistId, '_blank');
        }
    }

    loadExternalScript(scriptUrl: string) {
        return new Promise((resolve, reject) => {
            const scriptElement = document.createElement('script');
            scriptElement.src = scriptUrl;
            scriptElement.onload = resolve;
            document.body.appendChild(scriptElement);
        });
    }

    btnBackClicked() {
        this.location.back();
    }

    abstract refreshPage(): void;

    showLoading() {
        this.spinner.show('spinner1');
    }

    hideLoading() {
        this.spinner.hide('spinner1');
    }
}
