import { environment } from "../../environments/environment";

export class webService {

    public static getEvent = environment.root + 'getevent';
    public static getEventDetail = environment.root +  "geteventdetail";
    public static getPlaylistDetail = environment.root +  "getplaylistdetail";
    public static GetEventByUser = environment.rootHeroku + 'getEventByUser';
    public static GetSongRequestByKaraokeEvent = environment.root + 'getSongRequestByKaraokeEvent';
    public static GetSongRequestByStandard = environment.rootHeroku + 'getSongRequestByStandard';
    public static GetSongRequestByStandardForTicker = environment.rootHeroku + 'getSongRequestByStandardForTicker';
    public static GetSpotifyPlaylist = environment.rootHeroku + 'getSpotifyPlaylist';
    public static getPlaylistByPlaceId = environment.rootHeroku + 'getPlaylistByPlaceId';
    public static getPlaylistByPlaceIds = environment.rootHeroku + 'getPlaylistByPlaceIds';
    public static GetSpotifyPlaylistSuggest = environment.rootHeroku + 'getSpotifyPlaylistSuggest';
    public static StartPlaylist = environment.rootHeroku + 'startPlaylist';
    public static GetSongRequest = environment.root + 'getSongRequest';
    public static requestStandardSong = environment.rootHeroku + "requestStandardSong";
    public static upvote = environment.rootHeroku + "upvote";
    public static downvote = environment.rootHeroku + "downvote";
    public static GetConfig = environment.rootHeroku + "getConfig";
    public static GoogleNearbySearch = environment.rootHeroku + "nearbySearchNew";
    public static CreateAnAlias = environment.rootHeroku + "createAnAlias";
    public static NearbyParty = environment.rootHeroku + "nearbyParty";
    public static Search = environment.rootHeroku + "search";
    public static GeUserParty = environment.rootHeroku + "geUserParty";
    public static GetPartyById = environment.rootHeroku + "getPartyById";
    public static GetPartyPlaylist = environment.rootHeroku + "getPartyPlaylist";
    public static GetPartyPlaylistDetail = environment.rootHeroku + "getPartyPlaylistDetail";
    public static GetPlaylistSuggestion = environment.rootHeroku + "getPlaylistSuggestion";

    public static GoogleGeocode(lat: any, lng: any) {
        return `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDgJmdJ8zIRjCJL8eWGsy0FIItTgulrLK0&latlng=${lat},${lng}`
    }
}
