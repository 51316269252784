import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'AfterRequestModal',
  templateUrl: './AfterRequestModal.component.html'
})
export class AfterRequestModalComponent implements OnInit {
  @ViewChild("modal") public modal: ModalDirective;
  @Output() btnClicked = new EventEmitter<string>();
  
  title: string;

  constructor() { }

  ngOnInit(): void {
    
  }

  btnYesClicked() {
    this.btnClicked.emit("btnYesClicked");
    this.modal.hide();
  }

  btnCancelClicked() {
    this.btnClicked.emit("btnCancelClicked");
  }
}
