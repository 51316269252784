import {environment} from '../../environments/environment';

export class webService {

    public static GetEventByUser = environment.isDev ?
        environment.rootDev + 'getEventByUser' :
        environment.rootV1 + 'getEventByUser';

    public static GetSongRequestByStandard = environment.isDev ?
        environment.rootDev + 'getSongRequestByStandard' :
        environment.rootV1 + 'getSongRequestByStandard';

    public static GetSongRequestByStandardForTicker = environment.isDev ?
        environment.rootDev + 'getSongRequestByStandardForTicker' :
        environment.rootV1 + 'getSongRequestByStandardForTicker';

    public static GetSpotifyPlaylist = environment.isDev ?
        environment.rootDev + 'getSpotifyPlaylist' :
        environment.rootV1 + 'getSpotifyPlaylist';

    public static getPlaylistByPlaceId = environment.isDev ?
        environment.rootDev + 'getPlaylistByPlaceId' :
        environment.rootV1 + 'getPlaylistByPlaceId';

    public static getPlaylistByPlaceIds = environment.isDev ?
        environment.rootDev + 'getPlaylistByPlaceIds' :
        environment.rootV1 + 'getPlaylistByPlaceIds';

    public static GetSpotifyPlaylistSuggest = environment.isDev ?
        environment.rootDev + 'getSpotifyPlaylistSuggest' :
        environment.rootV1 + 'getSpotifyPlaylistSuggest';

    public static StartPlaylist = environment.isDev ?
        environment.rootDev + 'startPlaylist' :
        environment.rootV1 + 'startPlaylist';

    public static requestStandardSong = environment.isDev ?
        environment.rootDev + 'requestStandardSong' :
        environment.rootV1 + 'requestStandardSong';

    public static upvote = environment.isDev ?
        environment.rootDev + 'upvote' :
        environment.rootV1 + 'upvote';

    public static downvote = environment.isDev ?
        environment.rootDev + 'downvote' :
        environment.rootV1 + 'downvote';

    public static GetConfig = environment.isDev ?
        environment.rootDev + 'getConfig' :
        environment.rootV1 + 'getConfig';

    public static GoogleNearbySearch = environment.isDev ?
        environment.rootDev + 'nearbySearchNew' :
        environment.rootV1 + 'nearbySearchNew';

    public static CreateAnAlias = environment.isDev ?
        environment.rootDev + 'createAnAlias' :
        environment.rootV1 + 'createAnAlias';

    public static NearbyParty = environment.isDev ?
        environment.rootDev + 'nearbyParty' :
        environment.rootV1 + 'nearbyParty';

    public static Search = environment.isDev ?
        environment.rootDev + 'search' :
        environment.rootV1 + 'search';

    public static GeUserParty = environment.isDev ?
        environment.rootDev + 'geUserParty' :
        environment.rootV1 + 'geUserParty';

    public static GetPartyById = environment.isDev ?
        environment.rootDev + 'getPartyById' :
        environment.rootV1 + 'getPartyById';

    public static GetPartyPlaylist = environment.isDev ?
        environment.rootDev + 'getPartyPlaylist' :
        environment.rootV1 + 'getPartyPlaylist';

    public static GetPartyPlaylistDetail = environment.isDev ?
        environment.rootDev + 'getPartyPlaylistDetail' :
        environment.rootV1 + 'getPartyPlaylistDetail';

    public static GetPlaylistSuggestion = environment.isDev ?
        environment.rootDev + 'getPlaylistSuggestion' :
        environment.rootV1 + 'getPlaylistSuggestion';

    public static GoogleGeocode(lat: any, lng: any) {
        return `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyDgJmdJ8zIRjCJL8eWGsy0FIItTgulrLK0&latlng=${lat},${lng}`;
    }
}
