import { Injectable, NgZone } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { Subject } from 'rxjs';

export interface User {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  emailVerified: boolean;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  errorData: {};
  redirectUrl: string = "session";
  userState: any;
  loggedInEvent = new Subject<boolean>();

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone
  ) {
    
  }

  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
      .then((result) => {
        console.log(result);
        this.loggedInEvent.next(true);
      }).catch((error) => {
        console.log(error);
      })
  }

  idToken = async (): Promise<string> => {
    var token = "";
    try {
      const user = await this.afAuth.currentUser;
      if(user != undefined) {
        token = await user.getIdToken();
      }
    } catch (error) {
      console.error("failed", error);
    }

    return token;
  }

  get uid(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? user.uid : "";
  }

  get avatar(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? user.photoUrl : "";
  }

  get email(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? user.email : "";
  }

  get displayName(): string {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? user.displayName : "";
  }

  async SignIn(email: string, password: string): Promise<boolean> {
    try {
      var userCredential = await this.afAuth.signInWithEmailAndPassword(email, password);
      localStorage.setItem('user', JSON.stringify(userCredential.user));
      await this.router.navigate(['/session']);
      return true;
    } catch (error) {
      window.alert(error.message);
      return false;
    }
  }

  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  async SignOut() {
    await this.afAuth.signOut();
    localStorage.removeItem('user');
    this.router.navigate(['/login']);
  }
}
