import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "HelpModal",
  templateUrl: "./helpModal.component.html",
})
export class HelpModalComponent implements OnInit {
  @ViewChild("helpModal") public thisModal: ModalDirective;
  @Output() btnClicked = new EventEmitter<string>();

  slides = [];
  noWrapSlides = false;
  showIndicator = true;
  touch = true;
  activeSlide: any;

  optionLocation: AnimationOptions = {
    path: '/assets/location.json'
  };
  optionSearch: AnimationOptions = {
    path: '/assets/search.json'
  };
  optionSpotifyVibe: AnimationOptions = {
    path: '/assets/spotifyVibe.json'
  };
  optionSend: AnimationOptions = {
    path: '/assets/send.json'
  };

  constructor() {
    this.slides.push({ options: this.optionLocation, title: 'Open UJ.FM', des: `Open UJ.FM and start or join a party. \n \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0 \u00A0` });
    this.slides.push({ options: this.optionSearch, title: 'Request Songs', des: 'Request songs & vote on other people’s requests' });
    this.slides.push({ options: this.optionSpotifyVibe, title: 'Vibe Out', des: 'Playlists for the party update w/ the most liked requests' });
    this.slides.push({ options: this.optionSend, title: 'Tell People', des: 'Share UJ.FM or have guests scan the QR code on the playlist' });
  }

  ngOnInit(): void {

  }

  btnHelpRequestClicked() {
    this.btnClicked.emit("");
  }

  btnCloseClicked() {
    this.thisModal.hide();
  }
}
