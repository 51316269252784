import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "TipModal",
  templateUrl: "./tip-modal.component.html",
})
export class TipModalComponent implements OnInit {
  @ViewChild("tipModal") public tipModal: ModalDirective;
  @Output() btnClicked = new EventEmitter<string>();

  djName: string;
  profileUrl: string;
  djEmail: string;
  subTitle: string;
  customTip: number = 0;

  item1: string;
  item2: string;
  item3: string;

  constructor() { }

  ngOnInit(): void {
    this.item1 = "/assets/img/ic_beer_1.png";
    this.item2 = "/assets/img/ic_beer_2.png";
    this.item3 = "/assets/img/ic_beer_3.png";
  }

  updateItemIcon() {
    this.item1 = "/assets/img/ic_beer_1.png";
    this.item2 = "/assets/img/ic_beer_2.png";
    this.item3 = "/assets/img/ic_beer_3.png";
  }

  btn1Clicked() {
    this.btnClicked.emit("btnTip1Clicked");
  }

  btn2Clicked() {
    this.btnClicked.emit("btnTip2Clicked");
  }

  btn3Clicked() {
    this.btnClicked.emit("btnTip3Clicked");
  }

  btnTipCustomTipClicked() {
    this.btnClicked.emit("btnTipCustomTipClicked");
  }

  btnCancelTipModalClicked() {
    this.btnClicked.emit("btnCancelTipModalClicked");
    this.tipModal.hide();
  }
}
