import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
  } from "@angular/core";
  import { ModalDirective } from "ngx-bootstrap/modal";
  
  @Component({
    selector: "EmailModal",
    templateUrl: "./emailModal.component.html",
  })
  export class EmailModalComponent implements OnInit {
    @ViewChild("emailModal") public thisModal: ModalDirective;
    @Output() btnClicked = new EventEmitter<string>();

    email: string;

    constructor() { }
  
    ngOnInit(): void {
      
    }

    onEmailChange(value: string): void {
      this.email = value;
    }

    btnSubmitEmailClicked() {
      this.btnClicked.emit("");
    }
  }
  