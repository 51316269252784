import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: "QrCodeModal",
    templateUrl: "./qrCodeModal.component.html",
})
export class QrCodeModalComponent implements OnInit {
    @ViewChild("qrCodeModal") public thisModal: ModalDirective;
    @Output() btnSaveQrCodeClicked = new EventEmitter<string>();
    @Output() btnShareQRClicked = new EventEmitter<string>();
    @Output() btnCloseQRClicked = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {

    }

    onSaveQrCodeClicked() {
        this.btnSaveQrCodeClicked.emit("");
    }

    onShareQRClicked() {
        this.btnShareQRClicked.emit("");
    }

    onCloseQRClicked() {
        this.btnCloseQRClicked.emit("");
    }
}
