import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class MessageService{
  itemSubject = new Subject();
  itemBroatcast(item){
    this.itemSubject.next(item);
  }
  itemSubscribe(){
    return this.itemSubject.asObservable();
  }
}
