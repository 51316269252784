import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {RootSongRequestModel} from '../models/SongRequestModel';
import {webService} from './api';
import {RootConfigModel} from '../models/ConfigModel';
import {RequestedModel} from '../models/RequestedModel';
import {RootPartyModel} from '../models/PartyModel';
import {RootPlaylistModel} from '../models/PlaylistModel';
import {RootSongModel} from '../models/SongModel';
import {RootPlaylistSuggestionModel, RootSearchModel} from '../models/SpotifyTrackModel';
import {RootUserModel} from '../models/UserModel';
import {CommonModel} from '../models/CommonModel';
import {RootEventModel} from '../models/EventModel';
import {EventDetailsModel} from '../models/EventDetailModel';

@Injectable()
export class WebService {
    constructor(private http: HttpClient) {
    }

    async GetPlaylistByPlaceId(placeId: string) {
        const param = '?placeId=' + placeId;
        return this.http
            .get<any>(webService.getPlaylistByPlaceId + param)
            .toPromise();
    }

    async StartPlaylist(key: string, spotifyPlaylistId: string, currentPlaceId: string, minutes: string) {
        const params = new HttpParams()
            .set('key', key)
            .set('spotifyPlaylistId', spotifyPlaylistId)
            .set('placeId', currentPlaceId)
            .set('min', minutes);

        return await this.http
            .post<CommonModel>(webService.StartPlaylist, params)
            .toPromise();
    }

    async NearbyParty(lat: string, lng: string): Promise<any> {
        const param = `?lat=${lat}&lng=${lng}`;
        return await this.http
            .get<any>(webService.NearbyParty + param)
            .toPromise();
    }

    async GetPlaylistSuggestion(): Promise<RootPlaylistSuggestionModel> {
        return await this.http
            .get<RootPlaylistSuggestionModel>(webService.GetPlaylistSuggestion)
            .toPromise();
    }

    async GetSongRequestByStandard(currentPlaceId: string, addressName: string): Promise<RootSongRequestModel> {
        const param = new HttpParams()
            .set('placeId', currentPlaceId)
            .set('businessName', addressName);
        return await this.http
            .post<RootSongRequestModel>(webService.GetSongRequestByStandard, param)
            .toPromise();
    }

    async GetSpotifyPlaylist(currentPlaceId: string, addressName: string): Promise<any> {
        const param = new HttpParams()
            .set('placeId', currentPlaceId)
            .set('businessName', addressName);
        return await this.http
            .post<any>(webService.GetSpotifyPlaylist, param)
            .toPromise();
    }

    async GeUserParty(uid: string): Promise<RootUserModel> {
        const param = '?uid=' + uid;
        return await this.http
            .get<RootUserModel>(webService.GeUserParty + param)
            .toPromise();
    }

    async GetPartyById(id: string): Promise<RootPartyModel> {
        const param = '?id=' + id;
        return await this.http
            .get<RootPartyModel>(webService.GetPartyById + param)
            .toPromise();
    }

    async GetPartyPlaylist(id: string): Promise<RootPlaylistModel> {
        const param = '?id=' + id;
        return await this.http
            .get<RootPlaylistModel>(webService.GetPartyPlaylist + param)
            .toPromise();
    }

    async GetPartyPlaylistDetail(id: string, playlistKey: string): Promise<RootSongModel> {
        const param = '?id=' + id + '&playlistKey=' + playlistKey;
        return await this.http
            .get<RootSongModel>(webService.GetPartyPlaylistDetail + param)
            .toPromise();
    }

    async Search(q: string, id: string): Promise<RootSearchModel> {
        const param = `?q=${q}&id=${id}`;
        return await this.http
            .get<RootSearchModel>(webService.Search + param)
            .toPromise();
    }

    async requestStandardSong(firstName: string,
                              lastName: string,
                              songKey: string,
                              songName: string,
                              songArtist: string,
                              songThumb: string,
                              duration_ms: string,
                              explicit: string,
                              packageName: string,
                              packageId: string,
                              requestValue: string,
                              requestType: string,
                              latitude: string,
                              longitude: string,
                              addressName: string,
                              address: string,
                              currentPlaceId: string,
                              isPremium: string,
                              uuid: string): Promise<RequestedModel> {

        const params = new HttpParams()
            .set('firstName', firstName)
            .set('lastName', lastName)
            .set('songKey', songKey)
            .set('songName', songName)
            .set('songArtist', songArtist)
            .set('songThumb', songThumb)
            .set('duration_ms', duration_ms)
            .set('explicit', explicit)
            .set('packageName', packageName)
            .set('packageId', packageId)
            .set('requestValue', requestValue)
            .set('requestType', requestType)
            .set('latitude', latitude)
            .set('longitude', longitude)
            .set('businessName', addressName)
            .set('address', address)
            .set('placeId', currentPlaceId)
            .set('isPremium', isPremium)
            .set('uuid', uuid);

        return await this.http
            .post<RequestedModel>(webService.requestStandardSong, params)
            .toPromise();
    }

    async getConfig(): Promise<RootConfigModel> {
        return await this.http
            .get<RootConfigModel>(webService.GetConfig)
            .toPromise();
    }

    async getSongRequestByEvent(event: string): Promise<RootSongRequestModel> {
        const param = '?eventKey=' + event;
        return await this.http
            .get<RootSongRequestModel>(webService.GetSongRequestByKaraokeEvent + param)
            .toPromise();
    }

    async nearbySearch(lat: string, lng: string) {
        return await this.http.get<any>(webService.GoogleNearbySearch + `?lat=${lat}&lng=${lng}`)
            .toPromise();
    }

    async convertLatLngToAddress(lat: string, lng: string) {
        return await this.http.get<any>(webService.GoogleGeocode(lat, lng))
            .toPromise();
    }

    async getPlaylistByPlaceIds(placeIds: string) {
        const param = '?placeIds=' + placeIds;
        return await this.http.get<any>(webService.getPlaylistByPlaceIds + param)
            .toPromise();
    }

    async getSongRequest() {
        return await this.http
            .get<RootSongRequestModel>(webService.GetSongRequest)
            .toPromise();
    }

    async getSpotifyPlaylist(address: string) {
        const param = '?name=' + address;
        return await this.http
            .get<any>(webService.GetSpotifyPlaylist + param)
            .toPromise();
    }

    async getEvent(lat: string, lng: string) {
        const param =
            '?latitude=' + lat + '&longitude=' + lng + '&filterType=1';
        return await this.http.get<RootEventModel>(webService.getEvent + param)
            .toPromise();
    }

    async getEventByUser(uid: string) {
        const param = '?uid=' + uid;
        return await this.http
            .get<RootEventModel>(webService.GetEventByUser + param)
            .toPromise();
    }

    async getEventDetail(key: string) {
        const param = '?eventKey=' + key;
        return await this.http
            .get<EventDetailsModel>(webService.getEventDetail + param)
            .toPromise();
    }

    async getSongRequestByKaraokeEvent(key: string){
        const param = '?eventKey=' + key;
        return await this.http
            .get<RootSongRequestModel>(webService.GetSongRequestByKaraokeEvent + param)
            .toPromise();
    }

    async getSpotifyPlaylistSuggest(playlistId: string) {
        const param = '?id=' + playlistId;
        return await this.http
            .get<any>(webService.GetSpotifyPlaylistSuggest + param)
            .toPromise();
    }

    async getSongRequestByStandardForTicker(currentPlaceId: string, addressName: string) {
        const param = '?placeId=' + currentPlaceId + '&businessName=' + addressName;
        return await this.http
            .get<RootSongRequestModel>(webService.GetSongRequestByStandardForTicker + param)
            .toPromise();
    }

    async createAnAlias(createPartyKeyword: string, address: string, currentPlaceId: string, lat: string, lng: string) {
        const params = new HttpParams()
            .set("alias", createPartyKeyword)
            .set("address", address)
            .set("placeId", currentPlaceId)
            .set("lat", lat)
            .set("lng", lng);
        return await this.http
            .post<CommonModel>(webService.CreateAnAlias, params)
            .toPromise();
    }

    async getPlaylistDetail(eventKey: string, playlistKey: string) {
        const param = '?eventKey=' + eventKey + '&playlistKey=' + playlistKey;
        return await this.http
            .get<RootSongRequestModel>(webService.getPlaylistDetail + param)
            .toPromise();
    }

    async upvote(trackId: string, uuid: string, currentPlaceId: string, lat: string, lng: string) {
        const params = new HttpParams()
            .set('songKey', trackId)
            .set('uuid', uuid)
            .set('placeId', currentPlaceId)
            .set('lat', lat)
            .set('lng', lng);
        return await this.http
            .post<CommonModel>(webService.upvote, params)
            .toPromise();
    }

    async downvote(trackId: string, uuid: string, currentPlaceId: string, lat: string, lng: string) {
        const params = new HttpParams()
            .set('songKey', trackId)
            .set('uuid', uuid)
            .set('placeId', currentPlaceId)
            .set('lat', lat)
            .set('lng', lng);
        return await this.http
            .post<CommonModel>(webService.downvote, params)
            .toPromise();
    }
}
