import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private venue = '';
  public get Venue(): string {
    return this.venue;
  }
  public set Venue(venue: string) {
    this.venue = venue;
  }

  private isTipping = true;
  public get IsTipping(): boolean {
    return this.isTipping;
  }
  public set IsTipping(isTipping: boolean) {
    this.isTipping = isTipping;
  }

  private isShoutout = true;
  public get IsShoutout(): boolean {
    return this.isShoutout;
  }
  public set IsShoutout(isShoutout: boolean) {
    this.isShoutout = isShoutout;
  }

  public btnWrongVenueClicked = new Subject()
  public btnShoutoutClicked = new Subject()
  public btnTipClicked = new Subject()
  public btnMenuClicked = new Subject()
  public updateSpotifyPlaylist = new Subject()
  public getSpotifyPlaylist = new Subject()

  constructor() { }
}
