import {
    Component,
    Input,
    OnInit,
    ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: "FirstUseModal",
    templateUrl: "./firstUseModal.component.html",
})
export class FirstUseModalComponent implements OnInit {
    @ViewChild("firstUseModal") public thisModal: ModalDirective;

    @Input() playlistCountdownTimeOnly: string;
    @Input() addressName: string;
    @Input() address: string

    constructor() { }

    ngOnInit(): void {

    }
}
