export class Constants {
    public static SETTINGS_NUMBER = "SETTINGS_NUMBER"
    public static SETTINGS_LATEST_REQUEST = "SETTINGS_LATEST_REQUEST"
    public static SETTINGS_FIRST_TIME = "SETTINGS_FIRST_TIME"
    public static SETTINGS_UUID = "SETTINGS_UUID"
    public static SETTINGS_LAT = "lat"
    public static SETTINGS_LNG = "lng"
    public static SETTINGS_ADDRESS = "address"
    public static SETTINGS_PLACEID = "placeId"
    public static SETTINGS_SPOTIFY_PLAYLISTID = "SETTINGS_SPOTIFY_PLAYLISTID"
    public static SETTINGS_SPOTIFY_PLAYLIST_KEY = "SETTINGS_SPOTIFY_PLAYLIST_KEY"
    public static SETTINGS_SPOTIFY_PLAYLIST_MIN = "SETTINGS_SPOTIFY_PLAYLIST_MIN"
    public static SETTINGS_SYB_PLAYLISTID = "SETTINGS_SYB_PLAYLISTID"

    public static SETTINGS_PARTY_ID = "SETTINGS_PARTY_ID"
    public static SETTINGS_PARTY_PREMIUM_REQUEST_MODAL = "SETTINGS_PARTY_PREMIUM_REQUEST_MODAL"
    public static SETTINGS_PARTY_PREMIUM_REQUEST_MODAL_PARTY = "SETTINGS_PARTY_PREMIUM_REQUEST_MODAL_PARTY"

    public static EVENT_KEY = "EVENT_KEY"
    public static EVENT_NAME = "EVENT_NAME"
    public static EVENT_UID = "EVENT_UID"
    public static EVENT_DJEMAIL = "EVENT_DJEMAIL"
    public static EVENT_DJNAME = "EVENT_DJNAME"
    public static EVENT_PROFILE_URL = "EVENT_PROFILE_URL"
    public static EVENT_VENUE = "EVENT_VENUE"
    public static EVENT_IS_KARAOKE = "EVENT_IS_KARAOKE"
    public static EVENT_IS_TIPPING = "EVENT_IS_TIPPING"
    public static EVENT_IS_COLLECTS_EMAILS = "EVENT_IS_COLLECTS_EMAILS"
    public static EVENT_IS_COLLECTS_PHONE_NUMBERS = "EVENT_IS_COLLECTS_PHONE_NUMBERS"

    public static EVENT_BUS_WRONG_LOCATION = "EVENT_BUS_WRONG_LOCATION"
    public static EVENT_BUS_SHOUTOUT = "EVENT_BUS_SHOUTOUT"
    public static EVENT_BUS_TIPPING = "EVENT_BUS_TIPPING"
    public static EVENT_BUS_MENU = "EVENT_BUS_MENU"

    public static NUMBER_OF_SONG_REQUEST = 5
}
