// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDBGLlCrSDtYPVXtZkH0yF19zWcEbzEqwE",
    authDomain: "mejay-1542341684378.firebaseapp.com",
    databaseURL: "https://mejay-1542341684378.firebaseio.com",
    projectId: "mejay-1542341684378",
    storageBucket: "mejay-1542341684378.appspot.com",
    messagingSenderId: "439897506537",
    appId: "1:439897506537:web:510c11e309eb6ca9",
    measurementId: "G-QGYWZZZ6ZP"
  },
  root: "https://us-central1-mejay-1542341684378.cloudfunctions.net/web/",
  rootHeroku: "https://ujfm-cb86880689d4.herokuapp.com/web/"
};
