import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
    selector: "IntroModal",
    templateUrl: "./introModal.component.html",
})
export class IntroModalComponent implements OnInit {
    @ViewChild("introModal") public thisModal: ModalDirective;
    @Output() btnIntroRequestClicked = new EventEmitter<string>();
    @Output() btnHowItWorkClicked = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void {

    }

    onIntroRequestClicked() {
        this.btnIntroRequestClicked.emit("");
    }

    onHowItWorkClicked() {
        this.btnHowItWorkClicked.emit("");
    }
}
