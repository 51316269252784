import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap/modal";

@Component({
  selector: "DonateModal",
  templateUrl: "./donate-modal.component.html",
})
export class DonateModalComponent implements OnInit {
  @ViewChild("donateModal") public donateModal: ModalDirective;
  @Output() btnClicked = new EventEmitter<string>();

  customTip: number = 0;

  text1: string;
  text2: string;
  text3: string;

  item1: string;
  item2: string;
  item3: string;

  constructor() { }

  ngOnInit(): void {
    this.item1 = "/assets/img/ic_donate_1.png";
    this.item2 = "/assets/img/ic_donate_2.png";
    this.item3 = "/assets/img/ic_donate_3.png";
  }

  show() {
    this.updateItemIcon();
    this.donateModal.show();
  }

  updateText(type: string){
    if(type == "1") {
      this.text1 = "Donate $3 for a chance to sing";
      this.text2 = "Performance order is based on donation size. Anyone can add to your donation on the “Up Next” page on firstfriday.ujay.com";
      this.text3 = "Proceeds will go to First Friday Foundation";
    } else {
      this.text1 = "Donate $3 to Upvote";
      this.text2 = "Each dollar donated equals one upvote. Performance order and winner of best performance is based on the number of upvotes.";
      this.text3 = "Proceeds will go to First Friday Foundation";
    }
  }

  updateItemIcon() {
    this.item1 = "/assets/img/ic_donate_1.png";
    this.item2 = "/assets/img/ic_donate_2.png";
    this.item3 = "/assets/img/ic_donate_3.png";
  }

  btn1Clicked() {
    this.btnClicked.emit("btnTip1Clicked");
  }

  btn2Clicked() {
    this.btnClicked.emit("btnTip2Clicked");
  }

  btn3Clicked() {
    this.btnClicked.emit("btnTip3Clicked");
  }

  btnTipCustomTipClicked() {
    this.btnClicked.emit("btnTipCustomTipClicked");
  }
}
